import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import BaseFeatureCard from '@components/features-card/BaseFeatureCard';
import Title from '@components/features-card/Title';
import { HOMEPAGE_FEATURECARDS } from '../../constants/homepage';
import VideoComponent from '@components/common/VideoComponent/VideoComponent';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import { getPosterURL } from '@invideoio/web-shared/utils/common';
import { Card2Props, PosterType } from '../../types';

const Card2 = (props: Card2Props) => {
  const { brandLogos } = HOMEPAGE_FEATURECARDS.card2;
  const { heading, video, poster, platform, browser, DarkText } = props;
  const videoPoster: PosterType = poster?.[platform] || {
    defaultURL: poster?.url,
  };

  const istockWidth = {
    [PlatformType.Desktop]: 94,
    [PlatformType.Tablet]: 65,
    [PlatformType.Mobile]: 44,
  };

  const storyblocksWidth = {
    [PlatformType.Desktop]: 143,
    [PlatformType.Tablet]: 100,
    [PlatformType.Mobile]: 90,
  };

  const shutterstockWidth = {
    [PlatformType.Desktop]: 136,
    [PlatformType.Tablet]: 95,
    [PlatformType.Mobile]: 85,
  };

  const posterURL = getPosterURL(videoPoster, browser);

  return (
    <BaseFeatureCard
      heading={
        <Title
          heading={heading}
          headingColor={
            typeof DarkText === 'boolean' && DarkText
              ? 'iv-text-grey-85'
              : 'iv-text-grey-0'
          }
          heading2Class="md:iv-inline"
        />
      }
      cardClasses={ivclass('iv-justify-between')}
      media={
        <div
          className={ivclass(
            'iv-absolute',
            'iv-top-0 iv-left-0',
            'iv-h-full iv-w-full',
          )}
        >
          <VideoComponent
            className={ivclass(
              'iv-object-cover iv-h-full iv-w-full xl:iv-rounded-30 iv-rounded-25',
              'lazyload',
            )}
            autoPlay
            muted
            playsInline
            preload="auto"
            loop
            data-poster={posterURL}
          >
            <source data-src={video?.url} type="video/mp4" />
          </VideoComponent>
        </div>
      }
      tabs={
        <div className={ivclass('iv-flex iv-items-center')}>
          <div
            className={ivclass(
              'iv-relative',
              'iv-w-[94px] md:iv-w-[65px] sm:iv-w-[55px]',
              'iv-mr-40 md:iv-mr-25 sm:iv-mr-25',
            )}
          >
            <ImageComponent
              elementype="native"
              height="33"
              width={istockWidth[platform]}
              src={brandLogos.istock}
              alt="image"
              loading="lazy"
            />
          </div>
          <div
            className={ivclass(
              'iv-relative',
              'iv-w-[143px] md:iv-w-[100px] sm:iv-w-[90px]',
              'iv-mr-35 md:iv-mr-25 sm:iv-mr-20',
            )}
          >
            <ImageComponent
              height="31"
              width={storyblocksWidth[platform]}
              elementype="native"
              src={brandLogos.storyblocks}
              alt="image"
              loading="lazy"
            />
          </div>
          <div
            className={ivclass(
              'iv-relative',
              'iv-w-[136px] md:iv-w-[95px] sm:iv-w-[85px]',
            )}
          >
            <ImageComponent
              elementype="native"
              height="20"
              width={shutterstockWidth[platform]}
              src={brandLogos.shutterstock}
              alt="logo"
              loading="lazy"
            />
          </div>
        </div>
      }
    />
  );
};

export default Card2;
