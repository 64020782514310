import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

interface DescriptionProps {
  description: string;
}

const Description = ({ description }: DescriptionProps) => {
  return (
    <div
      className={ivclass(
        'xl:iv-m-body-3xl iv-m-body-2xl sm:iv-m-body-xl',
        'xl:iv-text-body-3xl iv-text-body-2xl sm:iv-text-body-xl',
        'iv-text-grey-85',
        'iv-font-medium',
      )}
    >
      {description}
    </div>
  );
};

export default Description;
