import VideoComponent from '@components/common/VideoComponent/VideoComponent';
import BaseFeatureCard from '@components/features-card/BaseFeatureCard';
import Title from '@components/features-card/Title';
import VideoTimelineTabs from '@components/features-card/VideoTimelineTabs';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { getPosterURL } from '@invideoio/web-shared/utils/common';
import { useRef } from 'react';
import { BrowserProps, Card3Type, PosterType } from '../../types';

interface Card7Props extends BrowserProps, Card3Type {}

const Card7 = (props: Card7Props) => {
  const { heading, video, videoTimeline, poster, browser } = props;
  const videoPoster: PosterType = poster?.desktop || {
    defaultURL: poster?.url,
  };

  const videoElement = useRef<HTMLVideoElement>(null);
  const posterURL = getPosterURL(videoPoster, browser);

  return (
    <BaseFeatureCard
      heading={
        <Title
          heading={heading}
          headingColor={
            typeof heading.DarkText === 'boolean' && !heading.DarkText
              ? 'iv-text-grey-0'
              : 'iv-text-grey-85'
          }
        />
      }
      cardClasses="iv-fluid-animation-gradient"
      media={
        <div className="iv-flex-grow iv-relative iv-my-40">
          <VideoComponent
            autoPlay
            muted
            playsInline
            preload="auto"
            loop
            ref={videoElement}
            className={ivclass(
              'iv-absolute',
              'iv-top-2/4 iv--translate-y-1/2 iv-left-0',
              'iv-max-h-full iv-w-full',
              'iv-object-cover',
              'lazyload',
            )}
            data-poster={posterURL}
          >
            <source data-src={video?.url} type="video/mp4" />
          </VideoComponent>
        </div>
      }
      tabs={
        <VideoTimelineTabs
          videoRef={videoElement}
          videoTimeline={videoTimeline}
        />
      }
    />
  );
};

export default Card7;
