import React, { useRef } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import BaseFeatureCard from '@components/features-card/BaseFeatureCard';
import Title from '@components/features-card/Title';
import { HOMEPAGE_FEATURECARDS } from '../../constants/homepage';
import VideoTimelineTabs from '@components/features-card/VideoTimelineTabs';
import VideoComponent from '@components/common/VideoComponent/VideoComponent';
import { getPosterURL } from '@invideoio/web-shared/utils/common';
import { BrowserProps, Card3Type } from '../../types';
import useTranslation from 'next-translate/useTranslation';
import { PlatformType } from '@invideoio/web-shared/constants/common';

interface Card4Props extends BrowserProps {
  layoutV3?: boolean;
  platform: PlatformType;
  card4?: Card3Type;
}

const createPosterURL = (
  platform: PlatformType,
  browser: string,
  video?: Card3Type,
) => {
  const videoposter =
    video?.poster?.[platform] ??
    HOMEPAGE_FEATURECARDS.card4.desktop.video.poster;

  const posterURL = getPosterURL(videoposter, browser);

  return posterURL;
};

const Card4 = ({ browser, layoutV3, card4, platform }: Card4Props) => {
  const { video, videoTimeline } =
    card4 ?? HOMEPAGE_FEATURECARDS.card4['desktop'];

  const url = video.url;

  const posterURL = createPosterURL(platform, browser, card4);

  const videoElement = useRef<HTMLVideoElement>(null);
  const { t } = useTranslation('home');

  return (
    <BaseFeatureCard
      heading={
        <Title
          heading={{
            heading1:
              card4?.heading.heading1 ?? t('card4.desktop.heading.heading1'),
          }}
        />
      }
      cardClasses={ivclass(!layoutV3 && 'iv-edit-audio-gradient')}
      media={
        <div className={ivclass('iv-relative', 'iv-flex-grow', 'iv-m-40')}>
          <VideoComponent
            autoPlay
            muted
            playsInline
            preload="auto"
            loop
            ref={videoElement}
            className={ivclass(
              'iv-absolute',
              'iv-top-2/4 iv--translate-y-1/2 iv-left-0',
              'iv-max-h-full iv-w-full',
              'iv-object-contain',
              'lazyload',
            )}
            data-poster={posterURL}
          >
            <source data-src={url} type="video/mp4" />
          </VideoComponent>
        </div>
      }
      tabs={
        <VideoTimelineTabs
          videoRef={videoElement}
          videoTimeline={videoTimeline.map((v, i) => ({
            ...v,
            name: v.name ?? t(`card4.desktop.videoTimeline.${i}.name`),
          }))}
        />
      }
    />
  );
};

export default Card4;
