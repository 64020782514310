import React, { RefObject, useEffect, useRef, useState } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

interface VideoTimelineTabsProps {
  videoTimeline: { start: number; end: number; name: string }[];
  videoRef: null | RefObject<HTMLVideoElement>;
  tabContainerClasses?: string;
  tabClasses?: string;
  defaultTabColor?: string;
  activeTabColor?: string;
}

const VideoTimelineTabs = ({
  videoRef,
  videoTimeline,
  tabContainerClasses = 'iv-overflow-auto iv-whitespace-nowrap iv-no-scroll-bar-container',
  tabClasses = 'xl:iv-mr-[28px] iv-mr-[19px] sm:iv-mr-15',
  defaultTabColor = 'iv-text-black-40',
  activeTabColor = 'iv-text-black-85',
}: VideoTimelineTabsProps) => {
  const [videoTime, setVideoTime] = useState(0);
  const throttleRef = useRef(false);

  const selectVideoTab = (time: number) => {
    setVideoTime(time);
    const ref = videoRef?.current;
    if (ref) {
      ref.currentTime = time;
    }
  };

  useEffect(() => {
    setVideoPlayListener();
    return () => {
      removeVideoPlayListener();
    };
  }, [videoRef]);

  const throttle = (callback: Function, time: number) => {
    if (throttleRef.current) return;

    throttleRef.current = true;
    setTimeout(() => {
      callback();
      throttleRef.current = false;
    }, time);
  };

  const setVideoPlayListener = () => {
    const ref = videoRef?.current;
    if (ref) {
      ref.addEventListener('timeupdate', () => {
        throttle(() => {
          const time = parseFloat(ref.currentTime.toFixed(2));
          setVideoTime(time);
        }, 900);
      });
    }
  };

  const removeVideoPlayListener = () => {
    const ref = videoRef?.current;
    if (ref) {
      ref.removeEventListener('timeupdate', () => {});
    }
  };

  return (
    <div
      className={ivclass(
        'iv-text-heading-4xs sm:iv-text-body-2xl',
        'iv-font-semibold',
        'iv-z-10',
        tabContainerClasses,
      )}
    >
      {Array.isArray(videoTimeline) &&
        videoTimeline.map((item, index) => (
          <span
            key={index}
            className={ivclass(
              'iv-relative',
              'iv-cursor-pointer',
              tabClasses,
              videoTime <= item.end && videoTime >= item.start
                ? activeTabColor
                : defaultTabColor,
            )}
            onClick={() => {
              selectVideoTab(item.start);
            }}
          >
            {item.name}
          </span>
        ))}
    </div>
  );
};
export default VideoTimelineTabs;
