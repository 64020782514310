import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { useContext } from 'react';

import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import BaseFeatureCard from '@components/features-card/BaseFeatureCard';
import Description from '@components/features-card/Description';
import Title from '@components/features-card/Title';
import { BrowserType } from '@invideoio/web-shared/constants/common';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import { RedirectContext } from '@store/store';
import useTranslation from 'next-translate/useTranslation';
import { HOMEPAGE_FEATURECARDS } from '../../constants/homepage';

interface Props {
  layoutV3?: boolean;
  platform: PlatformType;
  browser: BrowserType;
}

const Card8 = ({ layoutV3, platform, browser }: Props) => {
  const { image } = HOMEPAGE_FEATURECARDS.card8;
  const { alt } = image;
  const { t } = useTranslation('home');
  const { goToAiSignup } = useContext(RedirectContext);

  const onClick = () => {
    goToAiSignup();
    return false;
  };

  const { defaultUrl, webpUrl } = image[platform] ?? image['desktop'];

  const url = browser === BrowserType.Safari ? defaultUrl : webpUrl;

  return (
    <BaseFeatureCard
      heading={
        <Title
          heading={{ heading1: t('card8.heading.heading1') }}
          headingColor="iv-text-grey-0"
        />
      }
      cardClasses={'iv-justify-between iv-bg-grey-90'}
      media={
        <div className={ivclass('iv-relative iv-flex-grow iv-my-25')}>
          <ImageComponent
            elementype="native"
            src={url}
            alt={alt}
            className="iv-object-contain iv-absolute iv-inset-0 iv-w-full iv-h-full"
            loading="lazy"
          />
        </div>
      }
      description={
        layoutV3 ? undefined : <Description description={t('card8.text')} />
      }
      CTA={
        layoutV3 ? (
          <div
            className={ivclass(
              'iv-inline-flex iv-justify-center iv-items-center iv-cursor-pointer',
              'iv-rounded-full',
              'iv-border-2 iv-border-white-85',
              'iv-px-12 iv-py-6',
              'iv-h-32',
              'iv-mt-10',
              'iv-text-body-xl',
              'iv-text-white-85',
              'iv-font-semibold',
              'iv-transition hover:iv-bg-white-85 hover:iv-text-grey-100',
            )}
            onClick={onClick}
          >
            {t('card8.cta')}
          </div>
        ) : undefined
      }
    />
  );
};

export default Card8;
