import React, { useContext } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import BaseFeatureCard from '@components/features-card/BaseFeatureCard';
import Title from '@components/features-card/Title';
import { HOMEPAGE_FEATURECARDS } from '../../constants/homepage';
import useTranslation from 'next-translate/useTranslation';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import Description from './Description';
import { StateContext } from '@store/store';
import { BrowserType } from '@invideoio/web-shared/constants/common';

const Card9 = ({ layoutV3 }: { layoutV3?: boolean }) => {
  const { image } = HOMEPAGE_FEATURECARDS.card9;
  const { defaultUrl, webpUrl, alt } = image;
  const { t } = useTranslation('home');
  const { browser } = useContext(StateContext);

  const url = browser === BrowserType.Safari ? defaultUrl : webpUrl;

  return (
    <BaseFeatureCard
      heading={
        <Title
          heading={{
            heading1: t('card9.heading.heading1'),
          }}
        />
      }
      cardClasses={ivclass('iv-gradient-light-blue')}
      description={<Description description={t('card9.text')} />}
      media={
        <div className={ivclass('iv-relative iv-flex-grow iv-my-25')}>
          <ImageComponent
            elementype="native"
            src={url}
            alt={alt}
            className="iv-object-contain iv-absolute iv-inset-0 iv-w-full iv-h-full iv-max-w-[560px] iv-m-auto"
            loading="lazy"
          />
        </div>
      }
    />
  );
};

export default Card9;
