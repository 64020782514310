import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import Card1 from '@components/features-card/Card1';
import Card2 from '@components/features-card/Card2';
import Card3 from '@components/features-card/Card3';
import Card4 from '@components/features-card/Card4';
import Card5 from '@components/features-card/Card5';
import Card6 from '@components/features-card/Card6';
import Card7 from '@components/features-card/Card7';
import Card8 from '@components/features-card/Card8';
import Card9 from '@components/features-card/Card9';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import {
  BrowserProps,
  Card1Props,
  Card2Props,
  Card3Type,
  SimpleHeadingType,
} from '../../types';
import ErrorBoundary from '@components/ErrorBoundary';
import TitleH2BlockGradient from '@components/common/Title/TitleH2BlockGradient';

interface FeaturesCardProps extends BrowserProps {
  platform: PlatformType;
  sectionHead?: string;
  card1: Card1Props;
  card2: Card2Props;
  card3Mobile: Card3Type;
  card3Desktop: Card3Type;
  card4?: Card3Type;
  title?: SimpleHeadingType;
  card7?: Card3Type;
  layoutV3?: boolean;
  isAiPage?: boolean;
}

const FeaturesCard: React.FC<FeaturesCardProps> = ({
  platform,
  browser,
  sectionHead,
  card1,
  card2,
  card3Mobile,
  card3Desktop,
  card7,
  layoutV3,
  title,
  isAiPage,
  card4,
}) => {
  return (
    <section
      id="iv-features"
      className={ivclass(
        !layoutV3 && 'xl:iv-px-20 lg:iv-px-40 md:iv-px-25 sm:iv-px-20',
        'xl:iv-pt-200 lg:iv-pt-150 md:iv-pt-150 sm:iv-pt-75',
      )}
    >
      <div
        className={ivclass(
          !layoutV3 && 'xl:iv-pl-40 iv-pl-35 sm:iv-pl-[25px]',
          'sm:iv-px-20',
        )}
      >
        {sectionHead && !title && (
          <h2
            className={ivclass(
              'iv-text-grey-100',
              'xl:iv-text-heading-4xl iv-text-heading-2xl sm:iv-text-heading-2xs',
              'xl:iv-m-heading-3xl iv-m-heading-2xl sm:iv-m-heading-2xs',
              'iv-font-extrabold',
              'iv-text-center',
            )}
          >
            {sectionHead}
          </h2>
        )}
        {title && (
          <TitleH2BlockGradient
            heading={title}
            spanClass="iv-block sm:iv-inline"
          />
        )}
      </div>
      <div
        className={ivclass(
          'iv-grid',
          'iv-grid-cols-12 md:iv-grid-cols-8',
          'iv-gap-40 md:iv-gap-25 sm:iv-gap-20',
          layoutV3 && 'xl:iv-px-20 lg:iv-px-40 md:iv-px-25 sm:iv-px-20',
          'xl:iv-mt-75 iv-mt-50 sm:iv-mt-30',
        )}
      >
        <div
          className={ivclass(
            'iv-col-span-6 md:iv-col-span-4 sm:iv-col-span-12',
            'sm:iv-order-1',
          )}
        >
          <ErrorBoundary>
            <Card1
              heading={card1.heading}
              video={card1.video}
              image={card1.image}
              text={card1.text}
              btnText={card1.btnText}
              CTAUrl={card1.CTAUrl}
              DarkText={card1.heading.DarkText}
              layoutV3={layoutV3}
              platform={platform}
              browser={browser}
            />
          </ErrorBoundary>
        </div>

        <div
          className={ivclass(
            'iv-col-span-6 md:iv-col-span-4 sm:iv-col-span-12',
            'sm:iv-order-2',
          )}
        >
          <ErrorBoundary>
            <Card2
              platform={platform}
              browser={browser}
              heading={card2.heading}
              video={card2.video}
              poster={card2.poster}
              DarkText={card2.heading.DarkText}
            />
          </ErrorBoundary>
        </div>

        <div
          className={ivclass(
            'iv-col-span-12 md:iv-col-span-8 sm:iv-col-span-12',
            'sm:iv-order-3',
          )}
        >
          <ErrorBoundary>
            <Card3
              platform={platform !== 'mobile' ? 'desktop' : 'mobile'}
              browser={browser}
              mobile={card3Mobile}
              desktop={card3Desktop}
            />
          </ErrorBoundary>
        </div>

        <div
          className={ivclass(
            layoutV3
              ? 'iv-col-span-6 md:iv-col-span-4'
              : 'iv-col-span-7 md:iv-col-span-5',
            'sm:iv-col-span-12',
            'sm:iv-order-5',
          )}
        >
          <ErrorBoundary>
            {card4 ? (
              <Card4
                platform={platform}
                browser={browser}
                layoutV3={layoutV3}
                card4={card4}
              />
            ) : isAiPage ? (
              <Card9 layoutV3={layoutV3} />
            ) : (
              <Card4
                platform={platform}
                browser={browser}
                layoutV3={layoutV3}
              />
            )}
          </ErrorBoundary>
        </div>

        <div
          className={ivclass(
            layoutV3
              ? 'iv-col-span-6 md:iv-col-span-4'
              : 'iv-col-span-5 md:iv-col-span-3',
            ' sm:iv-col-span-12',
            'sm:iv-order-4',
          )}
        >
          <ErrorBoundary>
            {layoutV3 ? (
              isAiPage ? (
                <Card8 layoutV3 platform={platform} browser={browser} />
              ) : (
                <Card6 layoutV3 />
              )
            ) : (
              <Card5 />
            )}
          </ErrorBoundary>
        </div>

        {!layoutV3 && (
          <div
            className={ivclass(
              'iv-col-span-5 md:iv-col-span-3 sm:iv-col-span-12',
              'sm:iv-order-6',
            )}
          >
            <ErrorBoundary>
              <Card6 />
            </ErrorBoundary>
          </div>
        )}

        {card7 && (
          <div
            className={ivclass(
              'iv-col-span-7 md:iv-col-span-5 sm:iv-col-span-12',
              'sm:iv-order-7',
            )}
          >
            <ErrorBoundary>
              <Card7
                browser={browser}
                heading={card7.heading}
                video={card7.video}
                poster={card7.poster}
                videoTimeline={card7.videoTimeline}
              />
            </ErrorBoundary>
          </div>
        )}
      </div>
    </section>
  );
};

export default FeaturesCard;
