import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import BaseFeatureCard from '@components/features-card/BaseFeatureCard';
import Title from '@components/features-card/Title';
import { HOMEPAGE_FEATURECARDS } from '../../constants/homepage';
import Description from '@components/features-card/Description';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import useTranslation from 'next-translate/useTranslation';

const Card5 = () => {
  const { image } = HOMEPAGE_FEATURECARDS.card5;
  const { url, alt } = image;
  const { t } = useTranslation('home');

  return (
    <BaseFeatureCard
      heading={<Title heading={{ heading1: t('card5.heading.heading1') }} />}
      cardClasses={ivclass('iv-justify-between')}
      media={
        <div
          className={ivclass(
            'iv-flex-grow',
            'iv-relative',
            'iv-mt-[74px] sm:iv-mt-[35px] iv-mb-[63px] sm:iv-mb-[33px]',
          )}
        >
          <ImageComponent
            elementype="next"
            src={url}
            alt={alt}
            sizes="100vw"
            fill
            style={{
              objectFit: 'contain',
            }}
          />
        </div>
      }
      description={<Description description={t('card5.text')} />}
    />
  );
};

export default Card5;
