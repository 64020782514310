import React, { useContext } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import BaseFeatureCard from '@components/features-card/BaseFeatureCard';
import Title from '@components/features-card/Title';
import { AUTH_TYPE, HOMEPAGE_FEATURECARDS } from '../../constants/homepage';
import Description from '@components/features-card/Description';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import useTranslation from 'next-translate/useTranslation';
import {
  sendAmplitudeAnalyticEvent,
  AmplitudeEvents,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import { RedirectContext } from '@store/store';
import {
  ClickCtaUxSource,
  ClickCtaType,
  AnalyticsProductType,
} from 'src/types/analytics.types';

const Card6 = ({ layoutV3 }: { layoutV3?: boolean }) => {
  const { image } = HOMEPAGE_FEATURECARDS.card6;
  const { url, alt } = image;

  const { t } = useTranslation('home');

  const { handleClick } = useContext(RedirectContext);

  const onClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.exploreTemplates, {
      'Button Position': 'Features Section',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.FeaturesSelection,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product: AnalyticsProductType.STUDIO,
      },
    });
    handleClick('https://studio.invideo.io/workflow/marketing-templates', {
      tab: AUTH_TYPE.SIGNUP,
    });
  };

  return (
    <BaseFeatureCard
      heading={<Title heading={{ heading1: t('card6.heading.heading1') }} />}
      cardClasses={'iv-justify-between'}
      media={
        <div className={ivclass('iv-relative iv-flex-grow iv-my-25')}>
          <ImageComponent
            elementype="next"
            src={url}
            alt={alt}
            sizes="100vw"
            fill
            style={{
              objectFit: 'contain',
            }}
          />
        </div>
      }
      description={
        layoutV3 ? undefined : <Description description={t('card6.text')} />
      }
      CTA={
        layoutV3 ? (
          <div
            className={ivclass(
              'iv-inline-flex iv-justify-center iv-items-center iv-cursor-pointer',
              'iv-rounded-full',
              'iv-border-2 iv-border-grey-85',
              'iv-px-12 iv-py-6',
              'iv-h-32',
              'iv-mt-10',
              'iv-text-body-xl',
              'iv-text-grey-85',
              'iv-font-semibold',
              'iv-transition hover:iv-bg-grey-85 hover:iv-text-grey-0',
            )}
            onClick={onClick}
          >
            Start now
          </div>
        ) : undefined
      }
    />
  );
};

export default Card6;
