import React, { useRef } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import BaseFeatureCard from '@components/features-card/BaseFeatureCard';
import Title from '@components/features-card/Title';
import VideoTimelineTabs from '@components/features-card/VideoTimelineTabs';
import VideoComponent from '@components/common/VideoComponent/VideoComponent';
import { getPosterURL } from '@invideoio/web-shared/utils/common';
import { Card3Props, PosterType } from '../../types';

const Card3 = (props: Card3Props) => {
  const { platform, browser } = props;
  const { heading, video, videoTimeline, poster } = props[platform] || {};
  const videoPoster: PosterType = poster?.[platform] || {
    defaultURL: poster?.url,
  };
  const videoElement = useRef<HTMLVideoElement>(null);
  const posterURL = getPosterURL(videoPoster, browser);

  return (
    <BaseFeatureCard
      heading={
        <Title
          heading={heading}
          headingColor={
            typeof heading.DarkText === 'boolean' && heading.DarkText
              ? 'iv-text-grey-85'
              : 'iv-text-grey-0'
          }
          headingClass={ivclass(
            'iv-text-shadow',
            'xl:iv-text-heading-xl iv-text-heading-m',
            'xl:iv-m-heading-xl iv-m-heading-m',
            'sm:iv-hidden',
          )}
        />
      }
      cardClasses={ivclass('iv-justify-between sm:iv-h-[480px]')}
      media={
        <div
          className={ivclass(
            'iv-absolute',
            'iv-left-0 iv-top-0',
            'iv-w-full iv-h-full',
            'iv-z-0',
          )}
        >
          <VideoComponent
            autoPlay
            muted
            playsInline
            preload="auto"
            loop
            ref={videoElement}
            className={ivclass(
              'iv-object-cover iv-w-full iv-h-full',
              'xl:iv-rounded-30 iv-rounded-25',
              'lazyload',
            )}
            data-poster={posterURL}
          >
            <source data-src={video?.url} type="video/mp4" />
          </VideoComponent>
        </div>
      }
      tabs={
        <VideoTimelineTabs
          videoRef={videoElement}
          videoTimeline={videoTimeline}
          defaultTabColor={'iv-text-white-60'}
          activeTabColor={'iv-text-grey-0'}
        />
      }
    />
  );
};

export default Card3;
