import IVCard from '@invideoio/web-shared/components/Card';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

interface BaseFeatureCardProps {
  cardClasses?: string;
  heading: JSX.Element;
  media: JSX.Element;
  description?: JSX.Element;
  CTA?: JSX.Element;
  tabs?: JSX.Element;
  layoutV3?: boolean;
  additionalCtaClasses?: string;
}

const BaseFeatureCard = (props: BaseFeatureCardProps) => {
  const {
    heading,
    cardClasses,
    media,
    description,
    CTA,
    tabs,
    layoutV3,
    additionalCtaClasses,
  } = props;

  return (
    <IVCard>
      <div
        className={ivclass(
          'xl:iv-rounded-30 iv-rounded-25',
          'iv-relative',
          'iv-overflow-hidden',
        )}
      >
        <div
          className={ivclass(
            'iv-flex',
            'iv-flex-col',
            'xl:iv-py-40 iv-py-35 sm:iv-py-25',
            'xl:iv-h-[600px] lg:iv-h-[550px] md:iv-h-[500px] sm:iv-min-h-[320px]',
            cardClasses,
          )}
        >
          <div className={ivclass('xl:iv-px-40 iv-px-35 sm:iv-px-25')}>
            {heading}
          </div>

          {media}

          {(description || CTA) && (
            <div
              className={ivclass(
                'xl:iv-px-40 iv-px-35 sm:iv-px-25',
                additionalCtaClasses,
              )}
            >
              {description && description}
              {CTA && CTA}
            </div>
          )}

          {tabs && (
            <div
              className={ivclass(
                'iv-flex',
                'xl:iv-pl-40 iv-pl-35 sm:iv-pl-25',
                'iv-relative',
                'iv-top-[7px]',
                'iv-left-[-3px] sm:iv-left-[-1px]',
              )}
            >
              {tabs}
            </div>
          )}
        </div>
      </div>
    </IVCard>
  );
};

export default BaseFeatureCard;
